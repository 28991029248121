* {
  margin: 0;
  padding: 0;
}
html,
body {
  /* height: 100%; */
}
body {
  font-family: "Monaco", -apple-system, BlinkMacSystemFont, "Monaco", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e3c252;
}
a {
  color: #3b3b3b;
}
a:hover {
  color: #000;
}
li {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
