.codeAnalysis {
  background-color: #000000;
  color: #ffffff;
  max-height: auto;
  padding: 30px;
}
.quoteCard {
  max-width: 100%;
  height: 250px;
  margin: 15px;
  margin-top: 50px;
  padding: 15px;
  background-color: #000000;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 1.5vw;
}
.quote {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.charList > li {
  display: inline;
}
.normalButton {
  margin: 10px;
  padding: 5px;
  background-color: #ffffff;
  border-color: #000000;
}
.clickedButton {
  margin: 10px;
  padding: 5px;
  background-color: #000000;
  border-color: #ffffff;
  color: #ffffff;
}

.codeContainer {
  background-color: #ffffff;
  color: #000000;
  width: 80%;
  margin: 10px auto;
  padding: 5px;
}

@media screen and (max-width: 700px) {
  .quoteCard {
    font-size: 0.9em;
    height: auto;
  }
  .menu {
    height: 60px;
  }
}
