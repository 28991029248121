.grid {
  display: grid;
  grid-gap: 200px;
  grid-auto-rows: 100px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  padding: 0;
}
.h3Lab {
  font-family: "Monaco";
  font-size: 2em;
  margin-bottom: 5px;
  text-align: center;
  margin-top: 30%;
}

.textMenuLab {
  color: #ffffff;
  text-align: center;
  font-size: 1em;
}

.roundLinkLab {
  height: 17em;
  width: 17em;
  border-radius: 50%;
  background-color: #fff;
  color: #e3c252;
  transition: 0.5s;
  animation: 0.5s galleryLab;
  display: inline-block;
}
.roundLinkLab:hover {
  height: 18em;
  width: 18em;
  color: #e3c252;
  background-color: rgb(0, 0, 0);
}

@media screen and (max-width: 700px) {
  .roundLinkLab {
    height: 15em;
    width: 15em;

    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .roundLinkLab:hover {
    height: 15em;
    width: 15em;
    background-color: #fff;
  }
  .textMenuLab {
    font-size: 0.6em;
    color: #000;
    margin-left: 0;
    text-align: center;
    z-index: 0;
  }
  .h3Lab {
    text-align: center;
    font-size: 1.8em;
  }
}
