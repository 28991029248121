.social {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}
.socialImg {
  width: 2.5vw;
}

.social > a {
  margin: 5px;
}

@media screen and (max-width: 700px) {
  .social {
    width: auto;
    right: 0;
    flex-direction: row;
  }

  .socialImg {
    width: 1em;
  }
  .social > a {
    margin: 0px;
    height: 0px;
  }
}
