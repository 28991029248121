.main {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // height: auto;
}
.grid-animation {
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: center;
}
// .grid-animation {
//     margin: 40px auto;
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     grid-auto-flow: row;
//     justify-items: center;
//     gap: 5px;
// }
.div-animation {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 50%;
    border: 1px solid #ffffff;
}

@media only screen and (min-width: 768px) {
    .div-animation {
        width: 20%;
    }
}

// --------- EACH DIV ANIMATION

//--- 1. SCROLL
#scroll-outer {
    padding: 2px;
    display: flex;
    border: 1px solid #ffffff;
    width: 30px;
    height: 60px;
    border-radius: 100px;
}
#scroll-inner {
    background-color: #ffffff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    position: relative;
    animation-name: scroll;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes scroll {
    0% {
        top: 0;
    }
    50% {
        top: 0;
    }
    100% {
        top: 50%;
    }
}

//--- 2. HAMBURGER MENU
.hamburger {
}
.line {
    background-color: #000000;
    width: 30px;
    height: 2px;
    margin-bottom: 5px;
}

//--- 3. SQUARES
#squares {
    position: relative;
    background-color: #000000;
    justify-content: flex-end;
    align-items: center;
    animation: square 8s;
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
}
.square {
}
#square1 {
    background-color: #ff0101;
    height: 100%;
    width: 100%;
    animation: square1 8s linear;
    align-self: flex-start;
    justify-self: flex-end;
}
@keyframes square {
    0% {
        background-color: #000000;
    }
    20% {
        background-color: #ffffff;
    }
    21% {
        justify-content: flex-start;
    }
    40% {
        background-color: #000000;
    }
    60% {
        background-color: #000000;
    }
    80% {
        background-color: #000000;
    }
    100% {
        background-color: #000000;
    }
}
@keyframes square1 {
    0% {
        width: 0;
        height: 100%;
    }
    20% {
        width: 100%;
        height: 100%;
    }
    21% {
        width: 0%;
    }
    40% {
        width: 100%;
        height: 0%;
        background-color: #0066ff;
    }
    60% {
        width: 100%;
        height: 100%;
    }
    80% {
        width: 0;
        height: 100%;
    }
    100% {
        width: 100%;
        height: 100%;
    }
}

//--- 4. Ball

#ball1 {
    background-color: #000000;
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
#ball2 {
    background-color: #000000;
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
#ball3 {
    background-color: #000000;
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
@keyframes vibrating {
    0% {
        margin: 10px 10px 0 0;
    }
    25% {
        margin: -10px -10px 0 0;
    }
    50% {
        margin: 10px 10px 0 0;
    }
    75% {
        margin: -10px -10px 0 0;
    }
    100% {
        margin: 10px 10px 0 0;
    }
}
